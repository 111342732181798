import React, { useState } from 'react';
import axios from 'axios';

const UploadComponent = () => {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = () => {
        const formData = new FormData();
        formData.append('file', selectedFile);

        axios.post('https://backend.discountsmagnet.com/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',

            },
        })
            .then((response) => {
                console.log(response.data);
                // Handle successful upload
            })
            .catch((error) => {
                console.error(error);
                // Handle error
            });
    };

    return (
        <div>
            <input type="file" accept=".xlsx" onChange={handleFileChange} />
            <button onClick={handleUpload}>Upload</button>
        </div>
    );
};

export default UploadComponent;
