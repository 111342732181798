import Navbar from "../../components/Navbar/navbar";
import Styles from "./home.module.css"
import storelogo1 from "../../assets/Adidas-Logo 9.png"
import storelogo2 from "../../assets/Rectangle 31.png"
import cardlogo1 from "../../assets/Adidas-Logo 1.png"
import cardlogo2 from "../../assets/Adidas-Logo 2.png"
import cardlogo3 from "../../assets/Adidas-Logo 3.png"
import { useState, useEffect } from "react";
import Couponcard from "../../components/Couponcard/couponcard";
import Footer from "../../components/Footer/footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const Home = () => {
    const navigate = useNavigate()
    const [isHovered, setIsHovered] = useState(false);
    const [storeData, setstoreData] = useState([])
    const [storeDatatr, settoreDatatr] = useState([])
    const [storetitle, setstoretitle] = useState([])
    useEffect(() => {
        axios
            .get(
                "https://backend.discountsmagnet.com/discount_type",
            )
            .then((response) => {
                console.log(response.data.stores);
                setstoreData(response.data.stores.slice(0, 5))
                settoreDatatr(response.data.stores)
            })
            .catch((error) => {
                console.log(error);
            });
        axios
            .get(
                "https://backend.discountsmagnet.com/offershome",
            )
            .then((response) => {
                // console.log(response.data.details);
                setstoretitle(response.data.details.slice(0, 5))
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])


    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <>

            <div className={`${Styles.home}`}>
                <Navbar />
                <div className={`d-flex align-items-end ${Styles.herosec}`} >
                    <div className="container ">

                        <div className={`d-flex flex-wrap py-4 justify-content-evenly align-items-center ${Styles.hero}`}>
                            <p className="font18 an m-0 fontw7">Categories:</p>
                            <a className="font18 me-2 fontw4 an">Home & Garden</a>
                            <a className="font18 me-2 fontw4 an">Health</a>
                            <a className="font18 me-2 fontw4 an" >Clothing & Fashion</a>
                            <a className="font18 me-2 fontw4 an">Accessories</a>
                            <a className="font18 me-2 fontw4 an">Softwares</a>
                            <a className="font18 me-2 fontw4 an">Softwares</a>
                            <a className="font18 fontw4 an">Show All</a>

                        </div>
                        <select className={`${Styles.heroinpur}`}>
                            <option>Categories</option>
                            <option>Home</option>
                            <option>Health</option>
                            <option>Accessories</option>
                            <option>Softwares</option>

                        </select>
                    </div>
                </div>
                <div className="container mt-5 mt-md-70">
                    <h2 className="font30-md mb-5 mb-md-70 fontw4">Get Offers From Popular Store</h2>
                    <div className="row d-flex flex-wrap justify-content-between">
                        {storeData.length > 0 && storeData.map((item) => (
                            <div className="  col-6 col-sm-6  col-md-4 col-lg-2 d-flex  justify-content-center">
                                <div onClick={() => { navigate(`/store/${item.name}`) }} className={`m-2 ${Styles.cardstore}`} key={item.id}>
                                    <div className={`${Styles.imageWrapper}`}>
                                        <img className={`${Styles.cardstoreimg}`} src={`https://backend.discountsmagnet.com/uploads/${item.upload_img}`} alt="Store Image" />
                                    </div>
                                    <div className={`${Styles.overlay}`}>
                                        <span className="font18">{item.name}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="container mt-md-70 mt-5 pb=md-70 ">
                    <h2 className="pb-md-70 pb-5 fontw4 font30-md ">Most Popular Coupons & Deals</h2>
                    <div className={`row flex-wrap `}>
                        {storetitle.length > 0 && storetitle.map((item) => {
                            return (
                                <div className="col-lg-4 col-xl-3 col-md-6 mb-5 d-flex  justify-content-center">
                                    <Couponcard storeid={item.storeid} img={item.img} name={item.store_name} coupon={item.title} />
                                </div>)
                        })}
                    </div>

                </div>
                <div className="container  pb-70  ">
                    <h2 className="font30-md mb-md-70 fontw4">Stores With Best Discounts</h2>
                    <div className="d-flex  mt-4  row">
                        {storeDatatr.map((item) => {
                            return (
                                <div className="col-lg-2 col-md-4s col-12 col-sm-6 mb-4 ">
                                    <div onClick={() => { navigate(`/store/${item.name}`) }} className="d-flex align-items-center mt-md-5  me-md-5">
                                        <div>
                                            <div className={` me-2 ${Styles.storehold}`}>
                                                <img src={`https://backend.discountsmagnet.com/uploads/${item.upload_img}`} />
                                            </div>
                                        </div>

                                        <div className={`${Styles.comsd}`}>
                                            <h3 className="font18 m-0 fontw4">{item.name}</h3>
                                            <p className="font16 D9 m-0 fontw4">{item.category}</p>
                                        </div>

                                    </div>
                                </div>
                            )
                        })}





                    </div>
                    <div className="text-center">
                        <a onClick={() => { navigate("/mainstore") }}>Explore All</a>
                    </div>
                </div>
                <div className="container pb-70">
                    <h3 className="font30-md pb-md-70 pb-3 fontw4">Hello from <span className="fontw7">Salesupto</span> </h3>
                    <p className="font20-md  pb-md-50  fontw4">
                        As online shopping continues to rapidly expand on a global scale, coupon codes are a reliable and easy way to take advantage of discounts and keep more money in your pocket. Salesupto’s goal is to provide shoppers with fresh, working promo codes and deals for the brands they love.</p>
                    <p className="font20-md fontw4">

                        Salesupto works directly with retailers both large and small to publish valid coupons and promo codes for shoppers who want to save money on their online purchases (and in-store too!). Our coupons are and always will be free to use and share. The Salesupto team adds new offers daily so you never miss out on the best savings.</p>
                </div>
                <div className={` ${Styles.backlogosec}`}>
                    <div className="container" >
                        <div className={`row  d-flex justify-content-center p-md-5 `} >
                            <div className="col-lg-7 col-xl-8">
                                <p className="font23 text-center">Get coupon codes and online deals delivered straight to your inbox
                                </p>
                                <div className="d-flex pb-2">
                                    <input className={`${Styles.backlogoinput}`} />
                                    <button className={`font20 fontw5 ${Styles.backlogobtn}`}>Submit</button>
                                </div>
                                <p className="font19 text-center">Don't worry, we don't spam</p>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <Footer />
                </div>
            </div >

        </>
    )
}
export default Home;