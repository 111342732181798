import logo from "../../assets/RLOGO0-01 2.svg"
import Styles from "./footer.module.css"
import { Icon } from '@iconify/react';
const Footer = () => {
    return (
        <>
            <div className=" mt-5">
                <div className="row justify-content-between  ">
                    <div className="col-md-2 mb-4 mb-md-0 d-flex justify-content-center ">
                        <div className="d-flex align-items-center ">
                            <img src={logo} />
                            <h2 className="font25 fontw7 m-0">SalesUpto</h2>
                        </div>

                    </div>
                    <div className="col-md-4  mb-4 mb-md-0   d-flex flex-wrap align-items-center justify-content-between ">
                        <a className="D73 font16 fontw6 an">About </a>
                        <a className="D73 font16 fontw6 an">Contact Us</a>
                        <a className="D73 font16 fontw6 an"> Policy</a>
                        <a className="D73 font16 fontw6 an">Blogs</a>

                    </div>
                    <div className="col-md-3 ps-md-5  d-flex align-items-center justify-content-between">
                        <Icon style={{ fontSize: "30px" }} icon="akar-icons:instagram-fill" />
                        <Icon style={{ fontSize: "30px" }} icon="tabler:brand-pinterest" />
                        <Icon style={{ fontSize: "30px" }} icon="simple-icons:facebook" />

                    </div>
                </div>
                <div className="mt-4">
                    <p className="text-center font14">For the benefit of our clients, Cloudways exists. We help over 75K agencies, SMBs, and individuals save time and money so they can concentrate on what they love and expand their businesses. The Cloudways platform offers > 99.9% uptime, lightning-fast page loads, proactive app monitoring, dedicated workflows, industry-leading security.</p>
                </div>
                <div className={`mt-4 d-flex flex-wrap justify-content-between ${Styles.hr}`}>
                    <p className="mt-4">Copyright ©  2023 • All rights reserved</p>
                    <p className="mt-4">Salesupto is made with  &#x2764;   by Jataq LTD</p>
                </div>
            </div>
        </>
    )
}
export default Footer;