import logo from './logo.svg';
import './App.css';
import {
  createBrowserRouter,
  BrowserRouter,
  RouterProvider,
  Route,
  Routes,
} from "react-router-dom";
import Home from './pages/home/home';
import Store from './pages/store/store';
import MainStore from './pages/mainStore/mainstore';
import DashboardOut from './components/DashboardOut/DashboardOut';
import Affiliatestores from './pages/admin/Affiliate Stores/affiliatestores';
import Addstore from './pages/admin/addstore/addstore';
import Offers from './pages/admin/Offers/Offers';
import Addoffer from './pages/admin/Addoffer/Addoffer';
import Categories from './pages/admin/Categories/Categories';
import Networks from './pages/admin/Networks/Networks';
import Login from './pages/authpage/login';
import Editestore from './pages/admin/editestore/editestore';
import Editeoffer from './pages/admin/editeoffer/editeoffer';
import UploadComponent from './components/uploadcom/uploadcom';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/store/:sotreId" element={<Store />} />
        <Route path="/login" element={<Login />} />
        <Route path="/mainstore" element={<MainStore />} />
        <Route path="/dashboard" element={<DashboardOut />}>
          <Route path="affiliatestores" element={<Affiliatestores />} />
          <Route path="addstore" element={<Addstore />} />
          <Route path="offers" element={<Offers />} />
          <Route path="addoffer" element={<Addoffer />} />
          <Route path="categories" element={<Categories />} />
          <Route path="networks" element={<Networks />} />
          <Route path="editstore/:storeId" element={<Editestore />} />
          <Route path="editeoffer/:storeId" element={<Editeoffer />} />
          <Route path="uploadcomponent" element={<UploadComponent />} />

        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
