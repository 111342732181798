import Styles from "./navbar.module.css"
import { Icon } from '@iconify/react';
import logo from "../../assets/RLOGO0-01 2.svg"
import Hamburger from 'hamburger-react'
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
    const [activeLink, setActiveLink] = useState('home');
    return (
        <>
            <Desktop activeLink={activeLink} setActiveLink={setActiveLink} />
            <Mobile />
        </>

    )
}

const Desktop = (props) => {

    // useEffect(() => {
    //     // console.log(activeLink, "activeLink")
    // }, [activeLink])

    const handleLinkClick = (link) => {
        props.setActiveLink(link);
        localStorage.setItem("pageselected", link)
        // console.log(link, "link")
    };

    return (
        <div className={`${Styles.Desktop} `}>
            <nav className="navbar container navbar-expand-lg">
                <div className="container-fluid">
                    <a className="navbar-brand me-md-100 font25" href="#">
                        <span>
                            <img src={logo} alt="Logo" />
                        </span>
                        SalesUpto
                    </a>

                    <div className="row d-flex justify-content-between collapse navbar-collapse" id="navbarNav">
                        <form className="col-md-6 d-flex" role="search">
                            <input
                                className={`form-control ${Styles.serchin}`}
                                type="search"
                                placeholder="Search"
                                aria-label="Search"
                            />
                            <button className={`${Styles.Navbarbtn}`}>
                                <span>
                                    <Icon icon="charm:search" />
                                </span>
                            </button>
                        </form>
                        <ul className="col-md-5 d-flex justify-content-end navbar-nav">
                            <li className={`nav-item me-md-3 `}>
                                <NavLink
                                    exact
                                    to="/"
                                    className={`nav-link ${Styles.navlinds} ${localStorage.getItem("pageselected") === 'home' ? Styles.activeLink : ''}`}
                                    activeClassName={Styles.activeLink}
                                    onClick={() => handleLinkClick('home')}
                                >
                                    Home
                                </NavLink>
                            </li>
                            <li className={`nav-item me-md-3 `}>
                                <NavLink
                                    to="/mainstore"
                                    className={`nav-link ${localStorage.getItem("pageselected") === 'store' ? Styles.activeLink : ''} ${Styles.navlinds}`}
                                    activeClassName={Styles.activeLink}
                                    onClick={() => handleLinkClick('store')}
                                >
                                    Store
                                </NavLink>
                            </li>
                            <li className={`nav-item me-md-3 `}>
                                <NavLink
                                    to="/"
                                    className={`nav-link ${Styles.navlinds} ${props.activeLink === 'sale' ? Styles.activeLink : ''} `}
                                    activeClassName={Styles.activeLink}
                                    onClick={() => handleLinkClick('sale')}
                                >
                                    Upcoming Sale
                                </NavLink>
                            </li>
                            <li className={`nav-item me-md-3 `}>
                                <NavLink
                                    to="/"
                                    className={`nav-link ${Styles.navlinds} ${props.activeLink === 'blogs' ? Styles.activeLink : ''} `}
                                    activeClassName={Styles.activeLink}
                                    onClick={() => handleLinkClick('blogs')}
                                >
                                    Blogs
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};

const Mobile = (props) => {
    const [pop, setpop] = useState(false);
    const togglePop = () => {
        setpop(!pop);
    };
    const handleLinkClick = (link) => {
        props.setActiveLink(link);
        localStorage.setItem("pageselected", link)
        // console.log(link, "link")
    };
    return (
        <>
            <div className={` ${Styles.Mobile}`}>
                <div className={`p-2 d-flex justify-content-between align-items-center`}>
                    <div>
                        <a class="navbar-brand me-md-100  font20" href="#">
                            <span>
                                <img src={logo} />
                            </span>
                            SalesUpto
                        </a>
                    </div>
                    <div>

                    </div>
                    <div><a onClick={() => {
                        setpop(!pop);
                    }}><span><Hamburger toggled={pop} /></span></a></div>
                </div>
                {pop && <div className={`${Styles.overlay}`} onClick={togglePop}></div>}
                <div style={{
                    width: pop ? "250px" : "0",
                    // transition: "width .5s",
                }} className={` ${Styles.mobsider}`}>
                    <div className="p-2">
                        <div>
                            <div>
                                <a class="navbar-brand me-md-100  font20" href="#"> <span>  <img src={logo} />
                                </span>SalesUpto</a>
                            </div>
                        </div>
                        <ul className={` ps-2 navbar-nav mt-3 ${Styles.mobsiderlist} `}>
                            <li class="nav-item me-md-3 hr" >
                                <NavLink
                                    exact
                                    to="/"
                                    className={`nav-link ${Styles.navlinds} }`}
                                    activeClassName={Styles.activeLink}

                                >
                                    Home
                                </NavLink>

                            </li>
                            <li class="nav-item me-md-3 hr">
                                <NavLink
                                    to="/mainstore"
                                    className={`nav-link ${Styles.navlinds}`}

                                >
                                    Store
                                </NavLink>

                            </li>
                            <li class="nav-item me-md-3 hr">
                                <NavLink
                                    to="/mainstore"
                                    className={`nav-link ${Styles.navlinds}`}

                                >
                                    Upcoming Sale
                                </NavLink>
                            </li>
                            <li class="nav-item me-md-3">
                                <a className={` nav-link ${Styles.navlinds}`}>Blogs</a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Navbar;