import { useEffect, useState } from "react";
import Styles from "./affiliatestores.module.css"
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Icon } from '@iconify/react';
const Affiliatestores = () => {
    const { storeId } = useParams();
    const navigate = useNavigate();
    const [storedata, setstoredata] = useState([]);
    const [refresh, setrefresh] = useState(false)
    const [filterValue, setFilterValue] = useState("");
    const [filterValue1, setFilterValue1] = useState("none");
    const [filterValue2, setFilterValue2] = useState("none");

    const [categoryget, setcategoryget] = useState([]);
    const [networkget, setnetworkget] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = () => {
        const formData = new FormData();
        formData.append('file', selectedFile);

        axios.post('https://backend.discountsmagnet.com/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',

            },
        })
            .then((response) => {
                console.log(response.data);
                // Handle successful upload
            })
            .catch((error) => {
                console.error(error);
                // Handle error
            });
    };
    const filteredData = storedata.filter((item) => {
        const filterValueLower = filterValue.toLowerCase();
        const filterValue1Lower = filterValue1.toLowerCase();

        return (
            item.name.toLowerCase().includes(filterValueLower) ||
            item.category.toLowerCase().includes(filterValue1Lower)
        );
    });
    useEffect(() => {
        axios
            .get(
                "https://backend.discountsmagnet.com/categoryget",
            )
            .then((response) => {
                console.log(response.data);
                setcategoryget(response.data.details)
            })
            .catch((error) => {
                console.log(error);
            });
        axios
            .get(
                "https://backend.discountsmagnet.com/network",
            )
            .then((response) => {
                setnetworkget(response.data.details)
            })
            .catch((error) => {
                console.log(error);
            });


    }, [refresh])

    useEffect(() => {
        axios
            .get(
                `https://backend.discountsmagnet.com/filterStore/${window.btoa(filterValue1)}/${filterValue2}`,
            )
            .then((response) => {
                console.log(response.data.details
                );
                setstoredata(response.data.details)

            })
            .catch((error) => {
                console.log(error);
            });
        console.log(filterValue2)
    }, [filterValue1, refresh, filterValue2])
    return (
        <>
            <div className="px-5 w-100 pt-3">
                <div>
                    <p className="font18">Dashboard / <span className="fontw6">Affiliate Stores</span></p>
                </div>
                <div className="d-flex  align-items-center">
                    <h2 className="font30 me-2">Affiliate Stores</h2>
                    <p className="m-0 font18">(No. Total Stores: 20)</p>
                </div>
                <div className={`row d-flex justify-content-evenly  p-3 ${Styles.main}`}>
                    <div className="col-3">
                        <input onChange={(e) => setFilterValue(e.target.value)} className={` ${Styles.input1}`} />
                    </div>
                    <div className="col-3">
                        <select onChange={(e) => setFilterValue1(e.target.value)} className={` ${Styles.input2}`}>
                            <option value={"none"}>select category</option>
                            {categoryget.length > 0 && categoryget.map((item) => {
                                return (<option className="">{item.category_name}</option>)
                            })}

                        </select>
                    </div>
                    <div className="col-2 ">
                        <select onChange={(e) => setFilterValue2(e.target.value)} className={`${Styles.input3}`}>
                            <option value={"none"}>select a network</option>
                            {networkget.length > 0 && networkget.map((item) => {
                                return (<option className="">{item.network_name}</option>)
                            })}
                        </select>
                    </div>
                    <div className="col-2">
                        <button type="button" className={` ${Styles.uploadbtn}`} data-bs-toggle="modal" data-bs-target="#exampleModal">Import .xlx</button>
                    </div>



                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <input type="file" accept=".xlsx" onChange={handleFileChange} />

                                </div>
                                <div class="modal-footer">

                                    <button onClick={handleUpload} type="button" class="btn btn-primary"> Upload</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-2">
                        <button onClick={() => { navigate('/dashboard/addstore') }} className={` ${Styles.btn}`}>Add Store</button>
                    </div>
                </div>
                <div className={` row p-3 mt-3 ${Styles.main}`}>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">STORE NAME</th>
                                <th scope="col">OFFER(S)</th>
                                <th scope="col">CATEGORY</th>
                                <th scope="col">Image</th>
                                <th scope="col">VISITORS</th>
                                <th scope="col">COUNTRIES</th>
                                <th scope="col">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {storedata.map((item) => {
                                if (item.name.toLowerCase().includes(filterValue.toLowerCase()) || filterValue == "") {
                                    return (<tr>
                                        <th scope="row">{item.id}</th>
                                        <td>{item.name}</td>
                                        <td>{item.offers}</td>
                                        <td>{item.category}</td>
                                        <td>{item.upload_img}</td>

                                        <td>0</td>
                                        <td>{item.country}</td>
                                        <td>
                                            <span>
                                                <a onClick={() => {
                                                    axios
                                                        .delete(
                                                            `https://backend.discountsmagnet.com/store/${item.id}/${window.btoa(item.category)}/${item.network}`,

                                                            {
                                                                headers: {
                                                                    Authorization: `Bearer ${localStorage.getItem('access token')}`,
                                                                },
                                                            }
                                                        )
                                                        .then((response) => {
                                                            console.log(response.data);
                                                            setrefresh(!refresh)
                                                            if (response.data.status === 200) {
                                                                // NotificationManager.success('Success message', 'Category Added Successfully');
                                                                // navigate("/dashboard/affiliatestores")
                                                            }
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                        });
                                                }}><span><Icon icon="fluent:delete-16-regular" /></span>
                                                </a>
                                            </span>
                                            <span><a onClick={() => { navigate(`/dashboard/editstore/${item.id}`) }}><Icon icon="ep:edit" /></a></span>
                                        </td>
                                    </tr>)
                                } else {
                                    return null;
                                }
                            })}




                        </tbody>
                    </table>
                </div>
                <div className="row  mt-3  ">
                    <nav aria-label="Page d-flex justify-content-end navigation ">
                        <ul class="pagination">
                            <li class="page-item">
                                <a class="page-link" href="#" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>

            </div>
        </>
    )
}
export default Affiliatestores;