import Styles from './couponcard.module.css'
import { useNavigate } from 'react-router-dom'
const Couponcard = (props) => {
    const navigate = useNavigate()
    return (
        <>
            <div onClick={() => { navigate(`/store/${props.name}`) }} className={`p-3 ${Styles.Couponcard}`}>
                <div className={`${Styles.imgccon}`}>
                    <div className={`${Styles.container}`}>
                        <div className={`${Styles.imageWrapper}`}>
                            <img className={`${Styles.img}`} src={`https://backend.discountsmagnet.com/uploads/${props.img}`} alt="Image" />
                        </div>
                    </div>
                </div>
                <h2 className={`mt-4 font18 fontw7`}>{props.name}</h2>
                <p>{props.coupon}</p>
                <button className={`${Styles.cardbtn}`}>Shop Now</button>
            </div>
        </>
    )
}
export default Couponcard