import { useEffect } from "react";
import Styles from "./Offers.module.css"
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { Icon } from '@iconify/react';
const Offers = () => {
    const navigate = useNavigate();
    const { offerId } = useParams();
    const [storeget, setstoreget] = useState()
    const [offersget, setoffersget] = useState([])
    const [refresh, setrefresh] = useState(false)
    const [filterValue1, setFilterValue1] = useState("none");
    const [filterValue, setFilterValue] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = () => {
        const formData = new FormData();
        formData.append('file', selectedFile);

        axios.post('https://backend.discountsmagnet.com/offerupload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',

            },
        })
            .then((response) => {
                console.log(response.data);
                // Handle successful upload
            })
            .catch((error) => {
                console.error(error);
                // Handle error
            });
    };
    useEffect(() => {
        axios
            .get(
                "https://backend.discountsmagnet.com/store",
            )
            .then((response) => {
                console.log(response.data);
                setstoreget(response.data.details)
            })
            .catch((error) => {
                console.log(error);
            });
        axios
            .get(
                `https://backend.discountsmagnet.com/filterOffer/${window.btoa(filterValue1)}`,
            )
            .then((response) => {
                console.log(response.data, "asd");
                setoffersget(response.data.details)
            })
            .catch((error) => {
                console.log(error);
            });
        console.log(filterValue1)
    }, [refresh, filterValue1])
    return (
        <>
            <div className="px-5 w-100 pt-3">
                <div>
                    <p className="font18">Dashboard / <span className="fontw6">Offers</span></p>
                </div>
                <div className="d-flex  align-items-center">
                    <h2 className="font30 me-2">Offers</h2>
                    <p className="m-0 font18">(No. Total Stores: 20)</p>
                </div>
                <div className={`row d-flex justify-content-evenly  p-3 ${Styles.main}`}>
                    <div className="col-5">
                        <input onChange={(e) => setFilterValue(e.target.value)} className={` ${Styles.input1}`} />
                    </div>

                    <div className="col-3 ">
                        <select
                            onChange={(e) => setFilterValue1(e.target.value)}
                            className={Styles.input2}
                        >
                            <option value="none">Select category</option>
                            {storeget && storeget.length > 0 &&
                                storeget.map((item) => (
                                    <option key={item.category_id} value={item.name}>
                                        {item.name}
                                    </option>
                                ))}
                        </select>
                    </div>
                    <div className="col-2">
                        <button type="button" className={` ${Styles.uploadbtn}`} data-bs-toggle="modal" data-bs-target="#exampleModal">Import .xlx</button>
                    </div>
                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <input type="file" accept=".xlsx" onChange={handleFileChange} />

                                </div>
                                <div class="modal-footer">

                                    <button onClick={handleUpload} type="button" class="btn btn-primary"> Upload</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-2">
                        <button onClick={() => { navigate("/dashboard/addoffer") }} className={` ${Styles.btn}`}>Add Offers</button>
                    </div>
                </div>
                <div className={` row p-3 mt-3 ${Styles.main}`}>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">STORE NAME</th>
                                <th scope="col">TITLE</th>
                                <th scope="col">STATUS</th>
                                <th scope="col">CLICKS</th>
                                <th scope="col">ACTIONS</th>

                            </tr>
                        </thead>
                        <tbody>
                            {offersget.length > 0 && offersget.map((item) => {
                                if (item.title.toLowerCase().includes(filterValue.toLowerCase()) || filterValue == "") {
                                    return (<tr>
                                        <th scope="row">{item.id}</th>
                                        <td>{item.store_name}</td>
                                        <td>{item.title}</td>
                                        <td>{item.coupon_status}</td>
                                        <td>{item.clicks}</td>
                                        <td><span>
                                            <a onClick={() => {
                                                axios
                                                    .delete(
                                                        `https://backend.discountsmagnet.com/offer/${item.id}/${item.store}`,

                                                        {
                                                            headers: {
                                                                Authorization: `Bearer ${localStorage.getItem('access token')}`,
                                                            },
                                                        }
                                                    )
                                                    .then((response) => {
                                                        console.log(response.data);
                                                        setrefresh(!refresh)
                                                        if (response.data.status === 200) {
                                                            // NotificationManager.success('Success message', 'Category Added Successfully');
                                                            // navigate("/dashboard/affiliatestores")
                                                        }
                                                    })
                                                    .catch((error) => {
                                                        console.log(error);
                                                    });
                                            }}><span><Icon icon="fluent:delete-16-regular" /></span>
                                            </a>
                                        </span>
                                            <span><a onClick={() => { navigate(`/dashboard/editeoffer/${item.id}`) }}><Icon icon="ep:edit" /></a></span></td>

                                    </tr>)
                                }

                            })}



                        </tbody>
                    </table>
                </div>
                <div className="row  mt-3  ">
                    <nav aria-label="Page d-flex justify-content-end navigation ">
                        <ul class="pagination">
                            <li class="page-item">
                                <a class="page-link" href="#" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>

            </div>
        </>
    )
}
export default Offers;