import { useEffect } from "react"
import Styles from "./editestore.module.css"
import axios from "axios"
import { useState } from "react"
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useNavigate, useParams } from "react-router-dom";
const Editestore = () => {
    const { storeId } = useParams();
    const [categoryget, setcategoryget] = useState([])
    const [networkget, setnetworkget] = useState([])
    const [title, settitle] = useState()
    const [Slug, setSlug] = useState()
    const [Link, setLink] = useState()
    const [Aboutus, setAboutus] = useState()
    const [MetaDescription, setMetaDescription] = useState()
    const [Metatitle, setMetatitle] = useState()
    const [Logo, setLogo] = useState()
    const [Logos, setLogos] = useState()
    const [Category, setCategory] = useState("")
    const [oldCategory, setoldCategory] = useState()
    const [Network, setNetwork] = useState("")
    const [oldNetwork, setoldNetwork] = useState()
    const [Country, setCountry] = useState()
    const [getstore, setstore] = useState(null)
    const navigate = useNavigate();
    useEffect(() => {
        console.log(Logo, 'asasd3qwed')
        axios
            .get(
                "https://backend.discountsmagnet.com/categoryget",
            )
            .then((response) => {
                console.log(response.data);
                setcategoryget(response.data.details)
            })
            .catch((error) => {
                console.log(error);
            });
        axios
            .get(
                "https://backend.discountsmagnet.com/network",
            )
            .then((response) => {
                setnetworkget(response.data.details)
            })
            .catch((error) => {
                console.log(error);
            });
        axios
            .get(
                `https://backend.discountsmagnet.com/store/${storeId}`,
            )
            .then((response) => {
                // console.log(response.data.details.upload_img, "is")
                setstore(response.data.details)
                settitle(response.data.details.name)
                setSlug(response.data.details.slug)
                setLink(response.data.details.link)
                setAboutus(response.data.details.about)
                setMetaDescription(response.data.details.meta_description)
                setMetatitle(response.data.details.meta_title)
                // setLogo(response.data.details.upload_img)
                setoldCategory(response.data.details.category)
                setoldNetwork(response.data.details.network)
                setCountry(response.data.details.country)
            })
            .catch((error) => {
                console.log(error);

            });
        console.log(Logos, "Logos");
    }, [null, Logos])

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setLogos(URL.createObjectURL(file));
        setLogo(file)
    };
    return (
        <>
            <NotificationContainer />
            <div className="px-5 w-100 pt-3">
                <div>
                    <p className="font18">Dashboard / <span className="fontw6">Edite Stores</span></p>
                </div>
                <div className="d-flex  align-items-center">
                    <h2 className="font30 me-2">Edite a store</h2>

                </div>
                <div className="row">
                    <div className={`col-8`}>
                        <div className={`${Styles.fom1}`}>
                            <div className={`p-2 ${Styles.hr}`}>
                                <h2 className="font18 fontw6">Basic Information</h2>
                            </div>
                            <div className="p-3 " >
                                <div className="d-flex mb-4">
                                    <div className="d-flex w-50 flex-column me-2">
                                        <label className="font16 mb-2">Store Name</label>
                                        <input defaultValue={getstore != null ? getstore.name : ""} onChange={(e) => { settitle(e.target.value) }} className={`${Styles.inputd}`} />
                                    </div>
                                    <div className="d-flex w-50 flex-column">
                                        <label className="font16 mb-2" >Slug</label>
                                        <input defaultValue={getstore != null ? getstore.slug : ""} onChange={(e) => { setSlug(e.target.value) }} className={`${Styles.inputd}`} />
                                    </div>
                                </div>
                                <div className="d-flex mb-4 flex-column">
                                    <label className="font16 mb-2">Affiliate Link</label>
                                    <input defaultValue={getstore != null ? getstore.link : ""} onChange={(e) => { setLink(e.target.value) }} className={`${Styles.inputd}`} />
                                </div>
                                <div className="d-flex mb-4 flex-column">
                                    <label className="font16 mb-2">Store’s About Us</label>
                                    <textarea defaultValue={getstore != null ? getstore.about : ""} onChange={(e) => { setAboutus(e.target.value) }} className={`${Styles.inputt}`} />
                                </div>
                                <div className="d-flex mb-4 flex-column">
                                    <label className="font16 mb-2">Meta Description</label>
                                    <input defaultValue={getstore != null ? getstore.meta_description : ""} onChange={(e) => { setMetaDescription(e.target.value) }} className={`${Styles.inputd}`} />
                                </div>
                                <div className="d-flex mb-4 flex-column">
                                    <label className="font16 mb-2">Title Description</label>
                                    <input defaultValue={getstore != null ? getstore.meta_title : ""} onChange={(e) => { setMetatitle(e.target.value) }} className={`${Styles.inputd}`} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`col-4`}>
                        <div className={`mb-3 ${Styles.fom1}`}>
                            <div className={`p-2 ${Styles.hr}`}>
                                <h2 className="font18 fontw6">Upload Logo</h2>
                            </div>
                            <div className="p-3 d-flex align-items-end">
                                <div className={`${Styles.imgs} me-2`}>
                                    {Logos && <img src={Logos} className={`${Styles.imgsw}`} alt="Selected Image" />}
                                </div>
                                <div className={Styles.uploadbtn}>
                                    <input
                                        onChange={handleFileChange}
                                        type="file"
                                        className="upload-btn-input"
                                        id="file-input"
                                    />
                                    <label htmlFor="file-input" className="upload-btn-label">upload File</label>

                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.fom1}`}>
                            <div className={`p-2 ${Styles.hr}`}>
                                <h2 className="font18 fontw6">Additional Information</h2>
                            </div>
                            <div className="p-3">
                                <div className="d-flex mb-4 flex-column">
                                    <label className="font16 mb-2">Category</label>

                                    <select onChange={(e) => { setCategory(e.target.value) }} className={` ${Styles.inputd}`} >

                                        <option className="" selected>{getstore != null ? getstore.category : ""}</option>
                                        {categoryget.length > 0 && categoryget.map((item) => {
                                            return (<option className="">{item.category_name}</option>)
                                        })}

                                    </select>
                                </div>
                                <div className="d-flex mb-4 flex-column">
                                    <label className="font16 mb-2">Affiliated Network</label>

                                    <select onChange={(e) => { setNetwork(e.target.value) }} className={` ${Styles.inputd}`} >
                                        <option className="">select a Network</option>
                                        {networkget.length > 0 && networkget.map((item) => {
                                            return (<option className="">{item.network_name}</option>)
                                        })}
                                    </select>
                                </div>
                                <div className="d-flex mb-4 flex-column">
                                    <label className="font16 mb-2">Country</label>

                                    <select onChange={(e) => { setCountry(e.target.value) }} className={` ${Styles.inputd}`} >
                                        <option className="">as</option>
                                        <option className="">pakistan</option>
                                    </select>
                                </div>
                                <div >
                                    <button onClick={() => {
                                        const formData = new FormData();
                                        formData.append('file', Logo);
                                        formData.append('name', title);
                                        formData.append('slug', Slug);
                                        formData.append('about', Aboutus);
                                        formData.append('meta_description', MetaDescription);
                                        formData.append('meta_title', Metatitle);
                                        formData.append('category', Category === "" ? oldCategory : Category);
                                        formData.append('network', Network === "" ? oldNetwork : Network);
                                        formData.append('country', Country);
                                        formData.append('link', Link);
                                        axios
                                            .put(
                                                `https://backend.discountsmagnet.com/stores/${storeId}/${window.btoa(oldCategory)}/${oldNetwork}`,
                                                formData,
                                                {
                                                    headers: {
                                                        'Content-Type': 'multipart/form-data',
                                                        Authorization: `Bearer ${localStorage.getItem('access token')}`,
                                                    },
                                                }
                                            )
                                            .then((response) => {
                                                console.log(response.data);
                                                if (response.data.status === 200) {
                                                    NotificationManager.success('Success message', 'Category Added Successfully');
                                                    navigate("/dashboard/affiliatestores")
                                                }
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                            });
                                    }} className={`${Styles.savebtn}`}>Save Store</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div ></>
    )
}
export default Editestore 