import React, { useState } from 'react';
import { Icon } from '@iconify/react';
// import starDuotone from '@iconify-icons/ph/star-duotone

import { Rating } from "@smastrom/react-rating";

import "@smastrom/react-rating/style.css";

const CustomRating = (props) => {
    const [rating, setRating] = useState();




    return (
        <div className="custom-rating">
            <Rating readOnly value={props.value} />
        </div>
    );
};

export default CustomRating;
