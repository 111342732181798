import { useEffect } from "react";
import Footer from "../../components/Footer/footer";
import Navbar from "../../components/Navbar/navbar"
import Styles from "./mainstore.module.css"
import { Icon } from '@iconify/react';
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const MainStore = () => {
    const [storeData, setstoreData] = useState([])
    const [categoryget, setcategoryget] = useState([])
    const [category, setcategory] = useState("none")
    const navigate = useNavigate()

    useEffect(() => {
        axios
            .get(
                "https://backend.discountsmagnet.com/categoryget",
            )
            .then((response) => {
                console.log(response.data);
                setcategoryget(response.data.details)
            })
            .catch((error) => {
                console.log(error);
            });
        axios
            .get(
                `https://backend.discountsmagnet.com/discount_typed/${window.btoa(category)} `,
            )
            .then((response) => {
                console.log(response.data.stores);
                setstoreData(response.data.stores)
            })
            .catch((error) => {
                console.log(error);
            });
        console.log(category, "category");
    }, [category])
    return (
        <>
            <div className={`${Styles.storemain}`}>
                <Navbar />
                <div className="container pb-50 pt-50">
                    <h2 className="font23">Stores On SalesUpto</h2>
                    <div className="row ">
                        <div className="col-md-3 mb-3">

                            <div className={`${Styles.storedetails}`}>
                                <h2 className="m-2 font18 fontw5">Categories</h2>
                                <div className={`d-flex flex-column p-2 ${Styles.hr}`}>
                                    <a onClick={() => { setcategory("none") }} className="mb-1 an">All</a>
                                    {categoryget.length && categoryget.map((item) => {
                                        return (<a onClick={() => { setcategory(item.category_name) }} className="mb-1 an">{item.category_name}</a>)
                                    })}




                                </div>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className={`${Styles.store}`}>
                                {storeData.length > 0 && storeData.map((item) => {
                                    return (<div className={`d-flex align-items-center row ${Styles.storecon}`}>
                                        <div className={`col-4 col-md-2 ${Styles.imgtag}`}>
                                            <img className={``} src={`https://backend.discountsmagnet.com/uploads/${item.upload_img}`} />
                                        </div>

                                        <div className="col-md-5 col-lg-7 col-8 ">
                                            <p className="m-0 mb-2 font18 fontw5">{item.name}</p>
                                            <p className="m-0 font16 B1">{item.discount_count + item.coupon_count} Offers / {item.deal_count} Deals</p>
                                            <button onClick={() => {
                                                navigate(`/store/${item.name}`)
                                            }} className={`${Styles.linntn2}`}> Visit Store<span><Icon className="font18" icon="humbleicons:external-link" /></span> </button>
                                        </div>
                                        <div className="col-md-3 ">
                                            <button onClick={() => { navigate(`/store/${item.name}`) }} type="button" className={`${Styles.linntn}`}> Visit Store<span><Icon className="font18" icon="humbleicons:external-link" /></span> </button>
                                        </div>
                                    </div>)
                                })}

                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div className="container">
                <Footer />
            </div>
        </>
    )
}
export default MainStore