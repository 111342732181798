import { useEffect, useState } from "react"
import Styles from "./Addoffer.module.css"
import axios from "axios"
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useNavigate } from "react-router-dom";
const Addoffer = () => {
    const [storeget, setstoreget] = useState([])
    const [verification, setVerification] = useState(''); // State to store the selected verification status
    const [selectedOption, setSelectedOption] = useState('');
    const [Title, setTitle] = useState('');
    const [Description, setDescription] = useState('');
    const [ExpiryDate, setExpiryDate] = useState('');
    const [Link, setLink] = useState('');
    const [Coupon, setCoupon] = useState('');
    const [Store, setStore] = useState([]);
    const [Thumbnail, setThumbnail] = useState('');

    const navigate = useNavigate();
    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleVerificationChange = (e) => {
        setVerification(e.target.value);
    };
    useEffect(() => {
        axios
            .get(
                "https://backend.discountsmagnet.com/store",
            )
            .then((response) => {
                console.log(response.data);
                setstoreget(response.data.details)
            })
            .catch((error) => {
                console.log(error);
            });
        console.log(Store)
    }, [Store])
    return (
        <>
            <NotificationContainer />
            <div className="px-5 w-100 pt-3">
                <div>
                    <p className="font18">Dashboard / <span className="fontw6">Affiliate Stores</span></p>
                </div>
                <div className="d-flex  align-items-center">
                    <h2 className="font30 me-2">Add a new store</h2>

                </div>
                <div className="row">
                    <div className={`col-8`}>
                        <div className={`${Styles.fom1}`}>
                            <div className={`p-2 ${Styles.hr}`}>
                                <h2 className="font18 fontw6">Basic Information</h2>
                            </div>

                            <div className="p-3 " >
                                <div className="d-flex mb-4 flex-column me-2">
                                    <label className="font16 mb-2"> Title</label>
                                    <input onChange={(e) => { setTitle(e.target.value) }} className={`${Styles.inputd}`} />
                                </div>

                                <div className="d-flex mb-4 flex-column">
                                    <label className="font16 mb-2">Description </label>
                                    <input onChange={(e) => { setDescription(e.target.value) }} className={`${Styles.inputd}`} />
                                </div>
                                <div className="d-flex mb-4 flex-column">
                                    <label className="font16 mb-2">Expiry Date (Optional)</label>
                                    <input onChange={(e) => { setExpiryDate(e.target.value) }} type="date" className={`${Styles.inputd}`} />
                                </div>
                                <div className="d-flex mb-4 flex-column">
                                    <label className="font16 mb-2">Affiliate Link</label>
                                    <input onChange={(e) => { setLink(e.target.value) }} className={`${Styles.inputd}`} />
                                </div>
                                <label className="font16 mb-2">Discount Type</label>
                                <div className="mb-4">
                                    <label className="me-2">
                                        <input
                                            type="radio"
                                            name="option"
                                            value="coupon"
                                            checked={selectedOption === 'coupon'}
                                            onChange={handleOptionChange}
                                            className="me-2"
                                        />
                                        Coupon
                                    </label>
                                    <label className="me-2">
                                        <input
                                            type="radio"
                                            name="option"
                                            value="discount"
                                            checked={selectedOption === 'discount'}
                                            onChange={handleOptionChange}
                                            className="me-2"
                                        />
                                        Discount
                                    </label>
                                    <label className="me-2">
                                        <input
                                            type="radio"
                                            name="option"
                                            value="deal"
                                            checked={selectedOption === 'deal'}
                                            onChange={handleOptionChange}
                                        />
                                        Deal
                                    </label>
                                </div>

                                <div className="d-flex mb-4 flex-column">
                                    <label className="font16 mb-2"> Coupon Code</label>
                                    <input onChange={(e) => { setCoupon(e.target.value) }} className={`${Styles.inputd}`} />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className={`col-4`}>

                        <div className={`${Styles.fom1}`}>
                            <div className={`p-2 ${Styles.hr}`}>
                                <h2 className="font18 fontw6">Additional Information</h2>
                            </div>
                            <div className="p-3">
                                <div className="d-flex mb-4 flex-column">
                                    <label className="font16 mb-2">Store</label>

                                    <select onChange={(e) => {
                                        const selectedOption = e.target.options[e.target.selectedIndex];
                                        const customValue = selectedOption.getAttribute("data-custom");
                                        setStore({ id: e.target.value, name: customValue })
                                    }} className={` ${Styles.inputd}`} >
                                        <option className="">as</option>
                                        {storeget.length > 0 && storeget.map((item) => {
                                            return (<option value={item.id} data-custom={item.name} className="">{item.name}</option>)
                                        })}
                                    </select>
                                </div>

                                <div className="d-flex mb-4 flex-column">
                                    <label className="font16 mb-2"> Thumbnail</label>
                                    <input onChange={(e) => { setThumbnail(e.target.value) }} className={`${Styles.inputd}`} />
                                </div>
                                <label className="font16 mb-2">Coupon Status</label>
                                <div className="mb-4 ">
                                    <label className="me-2">
                                        <input
                                            type="radio"
                                            value="verified"
                                            checked={verification === 'verified'}
                                            onChange={handleVerificationChange}
                                            className="me-2"
                                        />
                                        Verified
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            value="unverified"
                                            checked={verification === 'unverified'}
                                            onChange={handleVerificationChange}
                                            className="me-2"
                                        />
                                        Unverified
                                    </label>

                                </div>
                                <div >
                                    <button onClick={() => {



                                        axios
                                            .post(
                                                "https://backend.discountsmagnet.com/offers",
                                                {
                                                    title: Title,
                                                    description: Description,
                                                    expiry: ExpiryDate,
                                                    link: Link,
                                                    discount_type: selectedOption,
                                                    coupon_code: Coupon,
                                                    store: Store.id,
                                                    thumbnail: Thumbnail,
                                                    coupon_status: verification,
                                                    store_name: Store.name,
                                                },
                                                {
                                                    headers: {

                                                        Authorization: `Bearer ${localStorage.getItem('access token')}`,
                                                    },
                                                }
                                            )
                                            .then((response) => {
                                                console.log(response.data);
                                                if (response.data.status === 200) {
                                                    NotificationManager.success('Success message', 'offer Added Successfully');
                                                    navigate("/dashboard/offers")
                                                }
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                            });
                                    }} className={`${Styles.savebtn}`}> Save Offer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div ></>
    )
}
export default Addoffer 