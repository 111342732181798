import Styles from "./storecoupen.module.css"
import vre from "../../assets/material-symbols_verified.png"
import { Icon } from '@iconify/react';
import { useState } from "react";
const StoreCoupon = (props) => {
    const [copied, setCopied] = useState(false);
    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(props.coupon_code);

            setCopied(true)
        } catch (error) {
            console.error('Failed to copy text: ', error);
        }
    };

    return (
        <>
            <div className={` row d-flex p-3 mb-3 ${Styles.card}`}>
                <div className={` col-4 me-2 d-flex justify-content-center align-items-center ${Styles.offer}`}>
                    <p className="text-center lh C71 m-0 font40 fontw7">{props.thumbnail}</p>
                </div>
                <div className={`col-sm-7 col-12 ${Styles.rel}`} >
                    <div className="d-flex align-items-center mb-2">
                        <img className={`${Styles.verimg}`} src={vre} /><p className="font16 fontw6 DF m-0">Verified Coupons</p>
                    </div>
                    <p className="font20 fontw5 mb-2 m-0">{props.title}</p>
                    <p className="font18 fontw3 mb-2 m-0">{props.description}</p>
                    <p className="font16 fontw6 B1 mb-2 m-0">93 - Uses</p>
                    <div>

                    </div>
                    <button onClick={() => { window.open(`${props.link}`, '_blank'); }} data-bs-toggle="modal" data-bs-target={`${`#` + props.id}`} className={`p-0 ${Styles.btn2}`}>****{props.coupon_code}</button>
                </div>
                <Modalbtn link={props.link} id={props.id} coupon_code={props.coupon_code} />
                {/* <div className={`row d-flex justify-content-between ${Styles.conta} `}>
                  
                </div> */}

                <div className="modal fade" id={`${props.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">25 Off Sitewide</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body px-5">
                                <div className={`d-flex justify-content-between align-items-center px-2 ${Styles.coponcode}`}>
                                    <p id={`${props.id}`}
                                        className="m-0">{props.coupon_code}</p>
                                    <button className={`${Styles.copybtn}`} onClick={handleCopy}>{!copied ? 'Copy' : "copied"}</button>
                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-between">
                                <a>Go to Store <span><Icon icon="humbleicons:external-link" /></span></a>
                                <div className="d-flex justify-content-between">
                                    <p className="m-0 me-3">Did This Offer Work??</p>
                                    <Icon className="font24 C71 me-2" icon="fluent:thumb-like-24-filled" />
                                    <Icon className="font24 red" icon="fluent:thumb-dislike-16-filled" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>





        </>
    )
}
const Modalbtn = (props) => {
    const [copied, setCopied] = useState(false);
    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(props.coupon_code);

            setCopied(true)
        } catch (error) {
            console.error('Failed to copy text: ', error);
        }
    };


    return (<>
        <div data-bs-toggle="modal" data-bs-target={`${`#` + props.id}`} className={`col-2 d-flex justify-content-center align-items-center  ${Styles.rel1}`} >
            <button onClick={() => { window.open(`${props.link}`, '_blank'); }} className={`p-0 ${Styles.btn}`}>****{props.coupon_code}</button>
        </div>
        <div className="modal fade" id={`${props.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">25 Off Sitewide</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body px-5">
                        <div className={`d-flex justify-content-between align-items-center px-2 ${Styles.coponcode}`}>
                            <p id={`${props.id}`}
                                className="m-0">{props.coupon_code}</p>
                            <button className={`${Styles.copybtn}`} onClick={handleCopy}>{!copied ? 'Copy' : "copied"}</button>
                        </div>
                    </div>
                    <div className="modal-footer d-flex justify-content-between">
                        <a>Go to Store <span><Icon icon="humbleicons:external-link" /></span></a>
                        <div className="d-flex justify-content-between">
                            <p className="m-0 me-3">Did This Offer Work??</p>
                            <Icon className="font24 C71 me-2" icon="fluent:thumb-like-24-filled" />
                            <Icon className="font24 red" icon="fluent:thumb-dislike-16-filled" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default StoreCoupon;